@import 'ui/utils';

.button {
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: rem(-18);
  left: 50%;
  transform: translateX(-50%);
  border-radius: rem(20) rem(20) 0 0;
  height: rem(18);
  width: rem(38);

  &--dark {
    background: cl(dark-background);
  }

  &--light {
    background: cl(border-dark);

    .buttonIcon path {
      fill: cl(middle-dark);
    }
  }

  &Icon {
    transition: transform 0.4s;
  }
}

.btnDirection {
  &--left,
  &--right {
    width: rem(18);
    height: rem(38);
    top: 50%;
    transform: translateY(-50%);
  }

  &--left {
    left: rem(-18);
    border-radius: rem(20) 0 0 rem(20);
    .buttonIcon {
      transform: rotate(270deg);
    }
  }

  &--right {
    left: 0;
    border-radius: 0 rem(20) rem(20) 0;
    .buttonIcon {
      transform: rotate(90deg);
    }
  }
  &--bottom {
    top: 0;
    border-radius: 0 0 rem(20) rem(20);
    .buttonIcon {
      transform: rotate(180deg);
    }
  }
}

.isClose {
  .buttonIcon {
    transform: rotate(180deg);
  }

  &.btnDirection {
    &--right {
      margin-left: 0;
      .buttonIcon {
        transform: rotate(270deg);
      }
    }
    &--left {
      .buttonIcon {
        transform: rotate(90deg);
      }
    }
    &--bottom {
      transform: rotate(180deg);
    }
  }
}
