@import 'ui/utils';

.conference {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  color: cl(middle-dark);
  background: cl(light-background);
}

.connection {
  &Wrapper {
    position: absolute;
    top: rem(5);
    right: rem(5);
  }

  width: rem(12);
  height: rem(12);
  cursor: pointer;
  border-radius: 50%;
  background: #000;
  transition: background 0.2s;

  &--ready {
    background: cl(theme);
  }

  &--pending {
    background: cl(warn);
  }

  &--error {
    background: cl(attention);
  }

  &--await {
    background: transparent;
  }
}

.confUser {
  margin-bottom: rem(4);

  &:last-child {
    margin-bottom: 0;
  }
}

.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
}

.tab {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: rem(9);
  border-top-left-radius: rem(9);
  font-size: rem(18);
  line-height: rem(23);
  padding: rem(13) 0;
  cursor: pointer;
  border: rem(1) solid transparent;
  border-bottom: none;

  &:only-child {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }

  &Active {
    cursor: auto;
    border-color: cl(border-light);
    background: cl(super-light);
  }

  &Icon {
    position: relative;
    margin-left: rem(10);

    &:after {
      position: absolute;
      top: rem(-2);
      right: rem(-5);
      width: rem(6);
      height: rem(6);
      border-radius: 50%;
      content: '';
      background-color: transparent;
      transition: background-color 0.3s;
    }
  }

  &Content {
    flex: 1 1;
    flex-basis: 0;
    overflow: auto;
  }

  &Notification {
    .tabIcon:after {
      background-color: cl(attention);
    }
  }
}

.footer {
  background: cl(super-light);
  border-top: rem(2) solid cl(border-dark);
  display: flex;
  padding: rem(6) rem(14) rem(9);
}

.meetId {
  margin-left: auto; // FIXME: drop auto margin
  display: flex;
  align-items: center;
  flex-direction: column;

  &Caption {
    font-size: rem(11);
    line-height: rem(14);
    margin-bottom: rem(2);
  }

  // FIXME: drop it
  button {
    display: inline-flex;
    align-items: center;
  }
}

.abilities {
  display: flex;
  justify-content: flex-start;
  width: 50%;
}

.ability {
  flex: 0 0 35%;
  text-align: center;

  &Caption {
    font-size: rem(11);
    line-height: rem(14);
    margin-top: rem(3);
  }
}

.recording {
  background-color: cl(deep-dark-blue);
  padding: rem(5) rem(10);
  border-radius: rem(4);
  margin: 0 rem(15) rem(10);
  color: cl(super-light);

  &:before {
    content: '';
    display: inline-block;
    width: rem(10);
    height: rem(10);
    border-radius: 50%;
    background-color: cl(attention);
    margin-right: rem(10);
    animation: blink infinite 2s;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
