@import './utils';

.form {
}

.formGroup {
  margin-bottom: rem(32);

  &:last-child {
    margin-bottom: 0;
  }
}
