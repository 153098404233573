.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
