@import 'ui/utils';

.dropdown {
  padding: rem(6) rem(15) rem(12);
  border-radius: rem(10);
  background-color: cl(pure-white);
}

.title {
  color: cl(middle-dark);
  font-size: rem(12);
  line-height: rem(16);
  padding-bottom: rem(4);
}

.socials {
  display: flex;
}

.social {
  display: block;
  margin: rem(0) rem(7.5);

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  img {
    width: rem(36);
    height: auto;
  }
}
