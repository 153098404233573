@import 'ui/utils';

.container {
  height: rem(120);
  transition: height 0.4s;
  position: relative;
  background-color: cl(dark-background);

  &IsClose {
    height: 0;
  }
}

.ui {
  display: flex;
  height: 100%;
}

.slides {
  display: flex;
  align-items: center;
  overflow: auto;
  height: 100%;
  flex: 1 1;
  flex-basis: 0;

  &:before,
  &:after {
    content: '';
    display: block;
    width: rem(26);
    height: rem(1);
    flex: 1 0 auto;
  }
}

.slide {
  height: rem(98);
  width: rem(124);
  padding: rem(9);
  margin: 0 rem(24);
  flex: 0 0 auto;
  cursor: pointer;
  border: rem(1) solid transparent;
  transition: background-color 0.3s, border-color 0.3s;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &Preview {
    display: block;
    height: 100%;
    width: auto;
    margin: 0 auto;
  }

  &Active {
    border-color: cl(middle-dark);
    background-color: cl(super-dark);
  }
}

.button {
  background-color: cl(super-dark);
  border: none;
  height: 100%;
  width: rem(40);
  padding: 0;
  flex: 0 0 auto;
  outline: none;
  cursor: pointer;

  &Icon {
    height: rem(17);
    width: rem(17);

    rect {
      transition: fill 0.2s;
    }
  }

  &Next {
    .buttonIcon {
      transform: rotate(180deg);
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled) {
    .buttonIcon rect {
      fill: cl(pure-white);
    }
  }
}
