@import 'ui/utils';

.message {
  padding: 0 rem(19);

  &Text {
    @include breakWord();

    a {
      color: cl(middle-dark);
    }
  }

  &Meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(2);
  }

  &Author {
    font-weight: 700;
  }

  &Date {
    font-size: rem(12);
    color: cl(border-dark);
  }

  &Unread {
    display: flex;
    justify-content: center;
    align-items: center;

    &:before,
    &:after {
      content: '';
      height: rem(1);
      background: cl(border-dark);
      opacity: 0.5;
      flex-basis: 50%;
    }
  }

  &UnreadTitle {
    border: rem(1) solid cl(border-dark);
    border-radius: rem(30);
    vertical-align: middle;
    text-align: center;
    font-size: rem(12);
    line-height: rem(16);
    color: cl(border-dark);
    padding: rem(1) rem(5);
  }
}

.chat {
  &Wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background: cl(super-light);
  }

  &Messages {
    overflow: auto;
    padding: rem(9) 0;
    flex: 1 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
  }

  &Message {
    margin-top: rem(12);
    margin-bottom: rem(9);

    &:first-child {
      margin-top: auto;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  padding: rem(12) rem(12);
  border-top: rem(2) solid cl(border-dark);

  &Input {
    margin-right: rem(10);
    flex: 1 1 auto;
  }

  &Button {
    flex-shrink: 0;
  }
}
