@import 'ui/utils';

.notifications {
  position: absolute;
  bottom: rem(20);
  right: rem(20);
  z-index: 10;
}

.notification {
  margin-top: rem(10);
}
