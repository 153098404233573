@import 'ui/utils';

.dropdown {
  position: relative;

  &Trigger {
  }

  &Frame {
    position: absolute;
    top: 100%;
    z-index: 1;
    background: cl(dark-background);
    border-radius: 0 0 rem(2) rem(2);
    box-shadow: 0 rem(3) rem(3) rgba(0, 0, 0, 0.25);

    &--top {
      top: auto;
      bottom: 100%;
      box-shadow: 0 rem(-3) rem(-3) rgba(0, 0, 0, 0.25);
      border-radius: rem(2) rem(2) 0 0;
    }

    &--center {
      left: 50%;
      transform: translateX(-50%);
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }
}
