@import 'ui/utils';

.select {
  @include unstyledList;

  max-height: rem(200);
  overflow: auto;
  border-radius: inherit;
  background-color: cl(super-dark);

  &Item {
    color: cl(border-dark);
    padding: rem(11) rem(16) rem(11) rem(22);
    cursor: pointer;
    transition: background-color 0.3s;

    @include breakWord();

    &:hover {
      background-color: cl(dark-background);
    }

    &:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    &:last-child {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    &Active {
      background-color: cl(dark-background);
      color: cl(pure-white);
      cursor: auto;
      display: flex;
      align-items: center;

      &:after {
        flex: 0 0 auto;
        content: '';
        display: block;
        background: url(../../assets/CheckIcon.svg) no-repeat no-repeat center center;
        height: rem(16);
        width: rem(16);
        margin-left: rem(10);
      }
    }

    &Text {
      flex: 1 1 auto;
    }
  }
}

.noWrap {
  .select {
    &Item {
      white-space: nowrap;

      &Text {
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1 0 auto;
        flex-basis: 0;
      }
    }
  }
}
