@import 'ui/utils';

.loginWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-size: cover;

  @include browserSafari {
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }
}

.loginBlock {
  background: cl(pure-white);
  box-shadow: rem(2) rem(2) rem(8) rgba(0, 0, 0, 0.25);
  border-radius: rem(20);
  width: 100%;
  max-width: rem(548);
  padding: rem(64);
  margin: rem(30) rem(20);
  position: relative;

  &Wide {
    max-width: rem(800);
  }

  @media screen and (max-height: 500px) {
    padding: rem(32) rem(64);
  }
}

.backButton {
  position: absolute;
  top: rem(20);
  left: rem(20);
  transition: top 0.4s, left 0.4s;
}

.logo {
  max-width: 100%;
  padding: 0 rem(25);
  margin: 0 auto rem(40) auto;
  display: block;
  max-height: rem(200);
}
