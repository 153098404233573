@import 'ui/utils';

.slider {
  background-color: cl(dark-background);
  padding: rem(7) rem(15);
  border-radius: rem(40);
  color: cl(pure-white);

  &Button {
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;

    svg rect {
      transition: fill 0.3s;
    }

    &Back {
      svg {
        transform: rotate(180deg);
      }
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:not(:disabled) {
      svg rect {
        fill: cl(pure-white);
      }
    }
  }

  &Text {
    margin: 0 rem(26);

    &Current {
      font-weight: 700;
    }
  }
}
