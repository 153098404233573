@import 'ui/utils';

.canvas {
  flex: 1 1;
  position: relative;
  overflow: hidden;

  &Color {
    &--gray {
      background-color: cl(middle-dark);
    }

    &--white {
      background-color: cl(pure-white);
    }
  }

  &Padded {
    padding: 0 5%;
  }
}

.frame {
  border: none;
  display: block;
  width: 100%;
  height: 100%;
}

.isViewOnly {
  pointer-events: none;
}

.video {
  width: 100%;
  height: 100%;
}

.frameWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1024px;
  height: 768px;
  will-change: transform;

  &Vertical {
    height: 1024px;
    width: 768px;
  }
}

.pointer {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.1s;
  will-change: transform;

  svg {
    height: rem(22);
    width: rem(22);

    :global {
      .pointer-body {
        fill: currentColor;
      }
    }
  }

  &Caption {
    position: absolute;
    top: rem(14);
    left: rem(14);
    background-color: currentColor;
    box-shadow: 0 rem(1) rem(2) rgba(0, 0, 0, 0.25);
    width: rem(18);
    height: rem(18);
    text-align: center;
  }

  &Text {
    text-transform: uppercase;
    color: cl(pure-white);
    font-size: rem(11);
    line-height: rem(14);
  }
}
