@import 'ui/utils';

@mixin buttonSize($size) {
  height: $size;

  &.isRounded {
    width: $size;
  }
}

@mixin buttonColor($bg, $border) {
  background: $bg;

  &.isBordered {
    border: rem(2) solid $border;
  }
}

.button {
  outline: none;
  font-size: rem(18);
  color: cl(super-light);
  border: 0;
  height: rem(56);
  border-radius: rem(30);
  cursor: pointer;
  transition: background 0.4s, border-color 0.4s, opacity 0.3s;

  @include removeTapHightlight();

  &:hover {
    opacity: 0.9;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  // colors
  &--primary {
    background: cl(deep-dark-blue);
  }

  &--secondary {
    color: cl(deep-dark-blue);
    background: cl(light-background);
  }

  &--dark {
    background: cl(dark-background);
  }

  &--theme {
    @include buttonColor(cl(theme), cl(dark-theme));
  }

  &--attention {
    @include buttonColor(cl(attention), cl(dark-attention));
  }

  &--simple {
    @include buttonColor(cl(pure-white), cl(border-dark));
  }

  // sizes
  &--xs {
    @include buttonSize(rem(21));

    font-size: rem(14);
    padding: 0 rem(10);
  }

  &--sm {
    @include buttonSize(rem(32));

    font-size: rem(14);
    padding: 0 rem(12);
  }

  &--xsm {
    @include buttonSize(rem(40));

    font-size: rem(14);
    padding: 0 rem(12);
  }

  &--lg {
    @include buttonSize(rem(64));
  }

  &--thin {
    &.isBordered {
      border-width: rem(1);
    }
  }
}

.isLoading {
  position: relative;

  &:before {
    $size: 25;
    position: absolute;
    top: 50%;
    left: rem(10);
    margin-top: rem(-($size / 2));
    height: rem($size);
    width: rem($size);
    content: '';
    display: block;
    border: rem(2) solid cl(pure-white);
    border-top: rem(2) solid transparent;
    border-radius: 100%;
    animation: spin 1s infinite linear;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.isBlock {
  display: block;
  width: 100%;
}

.isRounded {
  border-radius: 50%;
  width: rem(56);
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
