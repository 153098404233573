$colors: (
  pure-white: #fff,
  pure-black: #000,
  theme: #32c4c6,
  dark-theme: #26a1a3,
  attention: #de3838,
  warn: #f6c411,
  dark-attention: #ad2626,
  super-light: #f9f9f9,
  light-background: #eaeaea,
  border-light: #e5e5e5,
  border-dark: #bababa,
  middle-dark: #585858,
  deep-dark-blue: #424754,
  dark-background: #333333,
  super-dark: #202124,
  focused-bg: #c4c4c4,
  focused-shadow: #424754,
);

@function HexToRGB($hex) {
  @if $hex == transparent {
    @return $hex;
  } @else {
    @return red($hex), green($hex), blue($hex);
  }
}

@mixin globalColors {
  @each $key, $value in $colors {
    --#{$key}-color: #{HexToRGB($value)};
  }
}

@function cl($name, $opacity: false) {
  @if map-get($colors, $name) == transparent {
    @return var(--#{unquote($name)}-color);
  }

  @if $opacity {
    @return RGBA(var(--#{unquote($name)}-color), $opacity);
  } @else {
    @return RGB(var(--#{unquote($name)}-color));
  }
}
