@import 'ui/utils';

.deviceControls {
  display: flex;
  width: 100%;
  padding: rem(23) rem(30) rem(17);
  justify-content: space-between;

  &SmallCaptions {
    .deviceControlCaption {
      font-size: rem(12);
    }
  }
}

.deviceControl {
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &Caption {
    margin-top: rem(8);
    line-height: rem(18);
    text-align: center;
  }
}

.control {
  position: relative;

  &List {
    position: absolute;
    bottom: 0;
    right: 0;

    &TriggerIcon {
      transform: rotate(270deg);
      transition: transform 0.3s;
      height: rem(11);

      &Active {
        transform: rotate(90deg);
      }
    }
  }
}

.recordButton {
  $bezier: cubic-bezier(0.4, -0.9, 0.9, 1);
  position: relative;

  &:before {
    content: '';
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background-color: cl(attention);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -40%;
    margin-top: -40%;
    transition: width 0.3s $bezier, height 0.3s $bezier, border-radius 0.3s $bezier,
      margin-left 0.3s $bezier, margin-top 0.3s $bezier;
  }

  &.isRecording {
    &:before {
      width: 40%;
      height: 40%;
      border-radius: rem(6);
      margin-left: -20%;
      margin-top: -20%;
    }
  }
}
