@import 'ui/utils';

.userRow {
  display: flex;
  align-items: center;
  position: relative;
  background: cl(super-light);
  box-shadow: 0 rem(1) 0 #e6e6e6;

  &Fullview {
    .userColor {
      left: rem(32 + 8);
    }

    .userPreview {
      margin-right: 0;
      width: 100%;
      height: auto;
    }

    .userVideo {
      height: auto;
      width: 100%;
      max-height: rem(200);
    }

    .userDetails {
      padding: 0 rem(8);
      color: cl(pure-white);
      background: cl(pure-black, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: rem(32);
    }
  }
}

.user {
  &Name {
    position: relative;
    flex: 1 1 auto;
  }

  &Color {
    position: absolute;
    display: block;
    background-color: transparent;
    height: rem(3);
    width: rem(20);
    left: rem(85 + 32);
    bottom: 0;
  }

  &Details {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }

  &Preview {
    background: cl(border-dark);
    text-transform: uppercase;
    height: rem(48);
    width: rem(85);
    display: flex;
    align-items: center;
    justify-content: center;
    color: cl(super-light);
    font-size: rem(24);
  }

  &Self {
    margin-left: rem(5);
    color: cl(border-dark);
  }

  &Video {
    cursor: pointer;
    height: 100%;
    max-width: 100%;
  }

  &AudioInfo {
    width: rem(32);
    display: flex;
    align-items: center;
    justify-content: center;

    &MuteIcon {
      height: rem(18);

      &,
      path {
        stroke: #c0c0c0;
      }
    }
  }

  &Control {
    margin-right: rem(18);
    display: flex;
    align-items: center;

    &Button {
      cursor: pointer;
      background-color: transparent;
      border: none;
      padding: 0;
      outline: none;
    }

    &Item,
    &Button {
      margin-left: rem(5);
    }

    &Icon {
      width: rem(18);
    }
  }

  &AudioIconActive {
    :global(.mic-on-oval) {
      fill: cl(theme);
    }

    &,
    path {
      stroke: cl(theme);
    }
  }

  &PointerIconActive {
    &,
    path {
      fill: cl(theme);
      stroke: cl(theme);
    }
  }

  &FocusIconActive {
    &,
    path {
      fill: cl(theme);
    }
  }
}
