@import 'ui/utils';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.placeholder {
  width: rem(350);
  text-align: center;
  background-color: cl(pure-white);
  box-shadow: rem(2) rem(2) rem(8) cl(pure-black, 0.5);
  border-radius: rem(20);
  padding: rem(47) rem(32);
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: rem(12);
}

.icon {
  margin-right: rem(20);
}

.title {
  margin: 0;
  font-size: rem(24);
  font-weight: 700;
}

.caption {
  padding-top: rem(9);
}
