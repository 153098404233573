@import 'ui/utils';

.comment {
  display: flex;
  padding: rem(6) 0;

  &Meta {
    margin-right: rem(10);
    flex: 0 0 auto;
  }

  &Date {
    color: cl(border-dark);
    font-size: rem(12);
  }

  &Text {
    color: cl(middle-dark);

    @include breakWord();

    a {
      color: cl(middle-dark);
    }
  }
}

.comments {
  background-color: cl(super-light);
  border: rem(1) solid cl(border-light);
  display: flex;
  flex-direction: column;

  &Title {
    margin-top: rem(23);
    padding: 0 rem(18);
    font-size: rem(18);
    line-height: rem(23);
  }

  &List {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0 rem(18);
    overflow: auto;
  }

  @include mobile {
    .comments {
      &Heading {
        display: none;
      }
    }
  }
}

.close {
  @include cross(cl(focused-bg), cl(middle-dark));

  position: absolute;
  top: rem(28);
  right: rem(28);
}

.input {
  @include mobile {
    order: -1;
  }
}
