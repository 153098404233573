.timer {
  animation: timerAnmiation 2.5s infinite;
}

@keyframes timerAnmiation {
  0% {
    transform: scale(1);
  }

  35% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: rotate(-180deg);
  }
}

.isCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -13px;
  margin-top: -16px;
}
