@import 'ui/utils';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.track {
  position: relative;
  cursor: pointer;
  background: cl(middle-dark);
  content: '';
  display: block;
  flex: 1 0;
  min-width: rem(100);
  margin: rem(9) 0;
  height: rem(2);
  width: 100%;
  border-radius: rem(4);

  &:before {
    background: cl(middle-dark);
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: rem(20);
    width: rem(2);
    border-radius: rem(4);
  }
}

.button {
  position: absolute;
  top: 50%;
  margin-top: rem(-8);
  box-shadow: 0 rem(3) rem(3) rgba(0, 0, 0, 0.25);
  background-color: cl(border-light);
  border: rem(1) solid cl(pure-white);
  width: rem(16);
  height: rem(16);
  border-radius: 50%;
  cursor: pointer;
}

.before,
.after {
  white-space: nowrap;
  color: cl(pure-white);
  font-size: rem(11);
  text-align: center;
}

.before {
  margin-right: rem(12);
}

.after {
  margin-left: rem(12);
}

.isVertical {
  flex-direction: column;

  .track {
    min-width: auto;
    min-height: rem(100);
    margin: 0 rem(9);
    width: rem(2);
    height: 100%;

    &:before {
      width: rem(20);
      height: rem(2);
    }
  }

  .button {
    top: auto;
    left: 50%;
    margin-top: auto;
    margin-left: rem(-8);
  }

  .before {
    margin-right: auto;
    margin-bottom: rem(12);
  }

  .after {
    margin-left: auto;
    margin-top: rem(12);
  }
}
