@import '../utils';

.wrapper {
  display: flex;
  align-items: center;
}

.input {
  appearance: none;
  flex: 0 0 auto;
  color-adjust: exact;
  border: rem(2) solid cl(deep-dark-blue);
  width: 1.5em;
  height: 1.5em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;

  &[type='checkbox'] {
    border-radius: rem(5);
  }

  &[type='radio'] {
    border-radius: 50%;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    & + .label {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &:checked {
    background-color: cl(checkbox-active);
    border-color: cl(checkbox-active);
  }

  &:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }

  &:checked[type='radio'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='currentColor'/%3e%3c/svg%3e");
  }
}

.label {
  margin-left: rem(15);
  cursor: pointer;
}
