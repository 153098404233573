@import 'ui/utils';

.wrapper {
  text-align: center;
}

.loader {
  display: inline-block;
  vertical-align: middle;
  margin-right: rem(15);
}

.userName {
  padding: rem(10) 0;
  font-size: rem(16);
  font-weight: 700;
}
