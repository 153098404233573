@import 'ui/utils';

.heading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: rem(20);
  display: flex;
  justify-content: flex-end;
}

.welcome {
  margin-bottom: rem(36);

  &Heading {
    text-align: center;
    margin: rem(0);
    line-height: rem(32);
    font-size: rem(24);
    font-weight: 700;
  }

  &Details {
    text-align: center;
    margin: 0;
    padding: rem(7) 0;
    line-height: rem(18.13);
  }

  @include wider-than-subTablet {
    &JoinButton {
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.placeholderPage {
  display: flex;
  width: 100%;
  height: 100vh;
  font-size: rem(32);
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loginFromGrid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: rem(32);

  &Column {
    @include tighter-than-subTablet {
      flex: 1 1 100%;

      &:last-child {
        margin-top: rem(32);
      }

      &:first-child {
        margin-top: 0;
      }
    }

    @include wider-than-subTablet {
      flex: 1 1 50%;

      &:last-child {
        padding-left: rem(20);
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }
}

.terms {
  margin-bottom: rem(15);
}
