@import 'ui/utils';

.header {
  position: relative;
  height: rem(40);
  padding: 0 rem(12) 0 0;
  width: 100%;
  background-color: cl(super-dark);
  display: flex;
  z-index: 1;
  justify-content: space-between;
  transition: background-color 0.3s;

  @include stripeGrid();

  &ActionElement {
    & + .headerActionElement {
      margin-left: rem(20);
    }
  }

  &ActionButton {
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
    outline: none;
  }

  &Select {
    height: 100%;

    .headerActionButton {
      height: 100%;
    }
  }

  &BurgerButton {
    padding: 0 rem(12);

    &--dark {
      &.headerActionButtonActive {
        background-color: cl(dark-background);
      }
    }

    &--light {
      svg {
        path {
          fill: cl(deep-dark-blue);
        }

        line {
          stroke: cl(deep-dark-blue);
        }
      }
    }
  }

  &Name {
    color: cl(pure-white);
    font-size: rem(18);
    margin-left: rem(3);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &Fullscreen {
    background-color: transparent;
    padding: 0;
    height: auto;

    .header {
      &Left {
        display: none;
      }

      &Center {
        position: absolute;
        flex: 0 0 auto;
        left: 50%;
        top: rem(1);
        transform: translate(-50%, 0);
      }

      &Right {
        position: absolute;
        right: 0;
        top: 0;
        background-color: cl(dark-background, 0.6);
        flex-direction: column-reverse;
        border-bottom-left-radius: rem(20);
        padding: rem(12) rem(8);
      }

      &ActionElement {
        margin: rem(10) 0;

        &:first-child {
          margin-bottom: 0;
        }

        &:last-child {
          margin-top: 0;
        }

        & + .headerActionElement {
          margin-left: 0;
        }
      }

      &ActionButton {
        &:not(&Active) {
          .dislikeIcon,
          .likeIcon {
            path,
            rect {
              fill: transparent;
              stroke: cl(pure-white);
            }
          }
        }

        &:hover {
          .dislikeIcon,
          .likeIcon {
            path,
            rect {
              fill: cl(pure-white, 0.5);
            }
          }
        }

        &Active {
          .dislikeIcon,
          .likeIcon {
            path,
            rect {
              fill: cl(pure-white);
            }
          }
        }
      }
    }
  }

  &FullscreenIconActive {
    :global {
      .pointer {
        transition: transform 0.3s;
      }

      .pointer-top {
        transform-origin: 25% 75%;
        transform: rotate(180deg);
      }

      .pointer-bottom {
        transform-origin: 75% 25%;
        transform: rotate(180deg);
      }
    }
  }
}

.burgerMenu {
  width: rem(248);
  border-radius: 0 rem(2) rem(2) rem(2);
}

.dropdownMenu {
  padding: rem(4);
}

.presentationScenario {
  &:before {
    margin-right: rem(5);
    content: '⎿ ';
  }
}

.dislikeIcon {
  transform: scale(1, -1);
}

.likeIcon,
.dislikeIcon {
  path,
  rect {
    transition: fill 0.2s, stroke 0.2s;
  }
}

.layout {
  position: relative;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  width: 100%;
}

// Selfdetailing
.footer {
  background-color: cl(pure-white);

  @include stripeGrid();

  @include mobile {
    &Left {
      flex: 0 0 auto;
    }
  }

  &Wrapper {
    display: flex;
    justify-content: space-between;
    max-width: rem(1200);
    margin: auto;
    padding: rem(11) rem(10);
  }

  @include wider-than-mobile {
    background: cl(light-background);

    &Wrapper {
      padding: rem(11) rem(20);
    }
  }

  .headerActionButton {
    .headerFullscreenIcon {
      path {
        stroke: cl(deep-dark-blue);
      }
    }

    &:not(&Active) {
      .dislikeIcon,
      .likeIcon {
        path,
        rect {
          fill: transparent;
          stroke: cl(deep-dark-blue);
        }
      }
    }

    &:hover {
      .dislikeIcon,
      .likeIcon {
        path,
        rect {
          fill: cl(deep-dark-blue, 0.5);
        }
      }
    }

    &Active {
      .dislikeIcon,
      .likeIcon {
        path,
        rect {
          fill: cl(deep-dark-blue);
        }
      }

      &.commentButton {
        background-color: cl(light-background);
        border-radius: 50%;
      }
    }
  }

  .dislikeIcon,
  .likeIcon {
    width: rem(24);
    height: rem(24);
  }
}

.comments {
  @include wider-than-mobile {
    position: fixed;
    top: 0;
    bottom: rem(52);
    right: 0;
    width: rem(400);
  }
}
