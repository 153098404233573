@import 'ui/utils';

.soundVolume {
  display: flex;
  align-items: center;
  height: rem(18);

  > div {
    border-radius: rem(5);
    background-color: cl(theme);
    min-height: rem(4);
    width: rem(4);
    margin: rem(1.5);
    transition: height 0.1s;
    will-change: height;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &Animate {
    > div {
      animation: soundAnimation 400ms linear infinite alternate;

      &:first-child {
        animation-duration: 700ms;
      }

      &:last-child {
        animation-duration: 550ms;
      }
    }
  }
}

@keyframes soundAnimation {
  0% {
    height: rem(4);
  }

  100% {
    height: 100%;
  }
}
