@import '../utils';

.notificaiton {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: cl(super-dark);
  width: rem(344);
  border-radius: rem(10);
  opacity: 0.8;
  color: cl(pure-white);
  padding: rem(13) rem(15.7);
  transition: opacity 0.2s;
}

.isAnimation {
  animation: notificationFade 2s linear forwards;
}

@keyframes notificationFade {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.1;
  }
}

.title,
.caption {
  @include breakWord();
}

.caption {
  padding-top: rem(5);

  a {
    color: cl(pure-white);
  }
}

.close {
  @include cross(cl(pure-white));

  position: absolute;
  top: rem(13);
  right: rem(13);
}
